<template>
  <div class="page-container">
    <AppContent/>
    <Footer/>
  </div>
</template>

<script>
import Footer from "../../components/Footer";
import AppContent from "../../components/AppDev/AppContent";

export default {
  name: "AppDev",
  components:{Footer,AppContent}
}
</script>

<style scoped>

</style>

<template>
  <main class="flex-shrink-0">
    <div class="container">
      <div class="base-info mt-3">
        <div class="row">
          <div class="col">
            <span data-v-070132fe="">年　　龄</span>： 36岁
          </div>
          <div class="col">
            <span data-v-070132fe="">性　　别</span>： 男
          </div>

        </div>
        <div class="row">
          <div class="col">
            <span data-v-070132fe="">籍　　贯</span>： 湖南常宁
          </div>
          <div class="col">
            <span data-v-070132fe="">工作年限</span>： 15年
          </div>
        </div>
        <div class="row">
          <div class="col">
            <span data-v-070132fe="">电　　话</span>： <a href="tel:+8617688158011">17688158011</a>
          </div>
          <div class="col">
            <span data-v-070132fe="">邮　　箱</span>：<a
              href="364756334@qq.com">364756334@qq.com</a>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <span data-v-070132fe="">微　　信</span>： <span @click="copyString('.copy-btn-wx')" data-clipboard-text="junfeng2012wx" class="copy-btn-wx">junfeng2012wx&nbsp;(点击复制)</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <span data-v-070132fe="">QQ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>：<span
              @click="copyString('.copy-btn-qq')" class="copy-btn-qq" data-clipboard-text="364756334" >364756334&nbsp;(点击复制)</span>
          </div>
        </div>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          技能特长
        </div>
        <ul class="mr-3">
          <li>
            具备全栈开发能力。可以独立完成<b>网站(reactjs,vuejs)</b>，<b>小程序(uniapp,taro)</b>，<b>app(原生安卓，react
            native混合开发,uniapp,taro,flutter)</b>，<b>后台（nodejs）</b>的开发，上架或部署
          </li>
          <li>
            熟练掌握Java,Kotlin,JavaScript,reactjs,vuejs,nodejs编程, 具有扎实的面向对象编程技术功底
          </li>
          <li>
            熟练掌握基于Android原生应用(java或kotlin)开发以及基于ReactNative，uniapp,taro,flutter的混合应用开发
          </li>
          <li>
            熟练掌握HTML5、CSS/CSS3、JavaScript、Ajax、jQuery等Web前端技术
          </li>
          <li>
            熟练掌握pm2,nginx配置及服务器部署
          </li>
          <li>
            熟练使用Oracle、MySql,Sql Server,Sqlite等常用数据库以及编写Sql语句,存储过程，触发器等
          </li>
          <li>
            熟练使用Android studio，VSCODE,IntelliJ IDEA,webstorm,Eclipse,VS2010,PS ,MSProject,Redmine, Axure等工具
          </li>
          <li>
            熟悉Linux常用命令
          </li>
        </ul>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          工作经验
        </div>
        <dl class="row mr-3">
          <dd class="col">2019.03 <i class="ffyh">~</i> 2021.02</dd>
          <dd class="col-5">湖南美食流智能科技有限公司</dd>
          <dd class="col">技术经理</dd>
        </dl>
        <dl class="row mr-3">
          <dd class="col">2017.06 <i class="ffyh">~</i> 2019.03</dd>
          <dd class="col-5"><b>鹏博士电信传媒集团股份有限公司</b></dd>
          <dd class="col">技术经理</dd>
        </dl>
        <dl class="row mr-3">
          <dd class="col">2016.03 <i class="ffyh">~</i> 2017.06</dd>
          <dd class="col-5"><b>华润万家有限公司</b></dd>
          <dd class="col">互联网资深IT技术经理</dd>
        </dl>
        <dl class="row mr-3">
          <dd class="col">2015.05 <i class="ffyh">~</i> 2016.03</dd>
          <dd class="col-5">深圳市一二三零八网络科技有线公司</dd>
          <dd class="col">信息技术经理</dd>
        </dl>
        <dl class="row mr-3">
          <dd class="col">2015.05 <i class="ffyh">~</i> 2012.12</dd>
          <dd class="col-5">深圳市华宝电子科技有限公司</dd>
          <dd class="col">App技术主管</dd>
        </dl>
        <dl class="row mr-3">
          <dd class="col">2009.05 <i class="ffyh">~</i> 2012.11</dd>
          <dd class="col-5">深圳市天立航通科技有限公司</dd>
          <dd class="col">高级平台开发工程师</dd>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          教育背景
        </div>
        <dl class="row mr-3">
          <dd class="col">2005.09 <i class="ffyh">~</i> 2009.07</dd>
          <dd class="col-5">湖南师范大学</dd>
          <dd class="col">电子商务</dd>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          证书
        </div>
        <dl class="row mr-3">
          <dd class="col">2007.04</dd>
          <dd class="col-5">大学英语四级</dd>
          <dd class="col">459分</dd>
        </dl>
      </div>
    </div>
  </main>
</template>

<script>
import Banner from '../../components/Home/Banner'
import Service from '../../components/Home/Service'
import Industry from '../../components/Home/Industry'
import Cooperate from '../../components/Home/Cooperate'
import Cases from '../../components/Home/Cases'
import Customer from '../../components/Home/Customer'
import News from '../../components/Home/News'
import FriendLink from '../../components/Home/FriendLink'
import Footer from '../../components/Footer'
import Clipboard from 'clipboard'

export default {
  name: "Home",
  components: {Banner, Service, Industry, Cooperate, Cases, Customer, News, FriendLink, Footer},
  methods: {
    copyString(link) {
      console.log('复制内容', link)
      // this.link=link
      let clipboard = new Clipboard(link) // 这里括号里填写上面点击事件绑定的class名
      clipboard.on('success', (e) => {
        // 复制成功，提示根据自己项目实际使用的UI来写
        this.$toast.success('复制成功')
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        console.log('复制失败', e)
        // 不支持复制，提示根据自己项目实际使用的UI来写
        this.$toast.error('该浏览器不支持自动复制' + JSON.stringify(e.toString()))
        // 释放内存
        clipboard.destroy()
      })
    }
  }
}
</script>

<style scoped lang="less">
.base-info {
  font-size: 13px;
  color: #333;
  line-height: 2;
}

.title {
  font-size: 15px;
  color: #284967;
  background-color: rgba(40, 73, 103, 0.12);
  font-weight: 700;
}
</style>

// 该文件专门用于创建整个应用的路由器
import VueRouter from 'vue-router'
import Home from '../pages/Home'
import Cases from '../pages/Cases'
import CaseList from "../pages/Cases/CaseList";
import CaseDetails from "../pages/Cases/CaseDetails";
import AppDev from "../pages/AppDev";
import Applets from "../pages/Applets";
import About from "../pages/About";
import News from "../pages/News";
import Experience from "../pages/Experience";
import NewsList from "../pages/News/NewsList";
import NewsDetails from "../pages/News/NewsDetails";
//创建并暴露一个路由器
const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Home,
            name: 'home',
            meta: {title: '主页'}
        },
        {
            path: '/experience',
            component: Experience,
            name: 'experience',
            meta: {title: '项目经验'}
        },
        {
            path: '/case',
            component: Cases,
            name: 'case',
            meta: {title: '成功案例'},
            children: [
                {
                    path: 'list/:id?',
                    component: CaseList,
                    name: 'caseList',
                    meta: {title: '成功案例'},
                },
                {
                    path: 'details/:id',
                    component: CaseDetails,
                    name: 'caseDetails',
                    meta: {title: '成功案例'},
                },
                {
                    path: '/',
                    redirect: 'list/all',
                },
            ]
        },
        {
            path: '/app',
            component: AppDev,
            name: 'app',
            meta: {title: 'APP开发'}
        },
        {
            path: '/applets',
            component: Applets,
            name: 'applets',
            meta: {title: '微信小程序开发'}
        }, 
        {
            path: '/news',
            component: News,
            name: 'news',
            meta: {title: '新闻资讯'},
            children: [
                {
                    path: 'list/:id',
                    component: NewsList,
                    name: 'newsList',
                    meta: {title: '新闻资讯'},
                },
                {
                    path: 'details/:id',
                    component: NewsDetails,
                    name: 'newsDetails',
                    meta: {title: '新闻资讯'},
                },
                {
                    path: '/',
                    redirect: 'list/all',
                },
            ]
        },
        {
            path: '/about',
            component: About,
            name: 'about',
            meta: {title: '关于峰行'}
        },
    ]
})

//全局后置路由守卫————初始化的时候被调用、每次路由切换之后被调用
router.afterEach((to, from) => {
    //切换路由，页面直接滚动到顶部
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
})

export default router

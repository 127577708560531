<template>
  <div class="page-container">
    <AppletsContent/>
    <Footer/>
  </div>
</template>

<script>
import AppletsContent from "../../components/Applets/AppletsContent";
import Footer from "../../components/Footer";

export default {
  name: "Applets",
  components: {AppletsContent,Footer}
}
</script>

<style scoped>

</style>

<template>
  <main class="flex-shrink-0">
    <div class="container">
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          魁钉系统
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：OA平台</dd>
            <dd class="col-5">技术架构：Vuejs+jquery</dd>
          </dl>
          <li>
            一个专注于IC行业的办公自动化系统,功能强大,包含了项目，任务，进度，审核，协同，统计，需求，客户，wiki等功能
          </li>
          <dd class="col">平台地址：<a href="https://qucdin.com/user/AjaxForLogin.html" target="_blank">点击查看</a></dd>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          峰行科技管理平台
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：平台</dd>
            <dd class="col-5">技术架构：Nodejs+Vuejs</dd>
          </dl>
          <li>
            一个网站管理系统，采用前后端分离模式。服务端采用nodejs+koa2,前端采用vuejs。功能包括用户管理，角色管理，权限管理，文章管理，案例管理等等。
          </li>
          <dd class="col">平台地址：<a href="https://admin.fxcxy.com/" target="_blank">点击查看</a></dd>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          夸夸
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：小程序</dd>
            <dd class="col-5">技术架构：UniApp</dd>
          </dl>
          <li>
            一个应用于校外培训机构的小程序，主要帮助学生提高学习兴趣，同时能为培训学校增加曝光度，拓展生源。
          </li>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          全球搜钻App
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：APP</dd>
            <dd class="col-5">技术架构：android原生(kotlin)</dd>
          </dl>
          <li>
            此App主要是提供全球裸钻采购,实时钻石价格,钻戒定制及对戒定制服务,查看在线GIA证书,4C参数,裸钻高清图片及饰品来图定制等服务。是一款定制化电子商城应用。该项目采用kotlin语言开发，并采用MVVM架构实现
          </li>
          <dd class="col">下载地址：<a href="https://app.mi.com/details?id=com.theiajewel.app" target="_blank">小米应用商店</a>
          </dd>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          PowReady
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：APP</dd>
            <dd class="col-5">技术架构：React native</dd>
          </dl>
          <li>
            PowReady主要是为用户提供移动设备充电服务，通过地图快捷查找可借用和归还充电电池的地点。用户可以通过信用卡或apple
            pay快捷支付所需费用。该应用目前广泛应用于欧洲市场，目前在欧洲有上万家合作商户，支持多种语言。
          </li>
          <dd class="col">下载地址：<a href="https://apps.apple.com/cn/app/id1482046643" target="_blank">AppStore</a> | <a
              href="https://play.google.com/store/apps/details?id=com.powready.app" target="_blank">Play商店</a></dd>
          <dd class="col">测试账号：+441234567890,123456</dd>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          Ekey Merchant
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：APP</dd>
            <dd class="col-5">技术架构：React native</dd>
          </dl>
          <li>
            此App仅适用于零售、餐饮等商户，使商户能够创建他们唯一的营销平台
            在这个App中，商家可以创建凭证，包括姓名、号码、到期日期、费用等。
            商家可以在线购买代金券，定义一些促销，如抽奖，当客户中奖时每次抽奖，他们都会得到一张代金券。
            此外，该App提供了许多数据报告，以便商家做出更好的营销决策。
          </li>
          <dd class="col">下载地址：<a href="https://apps.apple.com/cn/app/id1612195181" target="_blank">AppStore</a> | <a
              href="https://play.google.com/store/apps/details?id=com.eKey.shop" target="_blank">Play商店</a></dd>
          <dd class="col">测试账号：tablemarketing@ekey,sz123456</dd>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          智慧食安公众端/企业端/监管端
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：APP</dd>
            <dd class="col-5">技术架构：React native</dd>
          </dl>
          <li>
            智慧食安app分为三个端，分别是监管端，机构端，公众端。监管端主要用于管部门远程实时对社会餐饮和校园餐饮工作的管理，机构端是提供给餐饮机构和学校后勤机构用于食品加工过程管理，公众端是面向社会公众和学生家长开放餐饮后厨食品加工过程的观看和监督。内含实时视频观看，餐饮评价，信息公示，检查评级记录，餐饮机构基础信息等内容
          </li>
        </dl>
        <dl class="row mr-3 ml-3">
          <dd class="col">监管端：<a href="https://apps.apple.com/cn/app/id1581625033" target="_blank">AppStore</a> | <a
              href="https://www.pgyer.com/59AH" target="_blank">蒲公英</a></dd>
          <dd class="col">测试账号：13807310010，123456</dd>
        </dl>
        <dl class="row mr-3 ml-3">
          <dd class="col">企业端：<a href="https://apps.apple.com/cn/app/id1581706903" target="_blank">AppStore</a> | <a
              href="https://www.pgyer.com/aW4O" target="_blank">蒲公英</a></dd>
          <dd class="col">测试账号：13574119818，123456</dd>
        </dl>
        <dl class="row mr-3 ml-3">
          <dd class="col">公众端：<a href="https://apps.apple.com/cn/app/id1581706933" target="_blank">AppStore</a> | <a
              href="https://www.pgyer.com/7uqh" target="_blank">蒲公英</a></dd>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          橘子洲售票机
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：APP</dd>
            <dd class="col-5">技术架构：Android</dd>
          </dl>
          <li>
            用于长沙著名景点橘子洲的用户自助购票设备，功能简单易用。省去游客排队购票的烦恼，方便快捷。。
          </li>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          惠精选APP
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：APP</dd>
            <dd class="col-5">技术架构：React native</dd>
          </dl>
          <li>
            惠精选是一款社区团购的电商app，支持多种类型的用户(会员，团长等)，为会员提供优质商品的选择服务，目前除了内地市场，还致力于新疆，内蒙市场的开拓。支持多种语言
          </li>
          <dd class="col">下载地址：<a href="http://www.lejingxuan.com/appdown.html" target="_blank">官网下载</a></dd>
          <dd class="col">测试账号：15574335669,1234</dd>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          美食流双屏收银APP
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：APP</dd>
            <dd class="col-5">技术架构：Android</dd>
          </dl>
          <li>
            此app是提供给商家收银使用，主要包括点单，结算，打印小票，收银播报，挂单，实体卡管理，会员管理，堂食接单和播报，订单统计，销售报表等。支持多种支付方式（微信，支付宝，现金，实体卡，积分，会员卡）及常用的营销方式（优惠券，改价，折扣）。而且实现了支持电子秤的，收银箱，外置网络小票打印机等
          </li>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          美食流精选
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：小程序</dd>
            <dd class="col-5">技术架构：Taro</dd>
          </dl>
          <li>
            这是一个电商购物小程序，他打通了线上线下的购物模式，用户通过线上购物获取的积分，可以到线下门店使用积分支付。该小程序包含了商城，积分，查找线下门店，优惠券，活动等功能。
          </li>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          小朋管理平台
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：管理平台</dd>
            <dd class="col-5">技术架构：Node.js+Vuejs</dd>
          </dl>
          <li>
            小朋管理平台是基于小朋电商管理后台的一个报表统计及客服管理中台，为运营提供决策数据，为财务提供方便的对账功能，为客服提供便利的数据查询等。解决了原来的电商管理后台难用，操作复杂的问题。其功能主要包括城市报表，财务报表，运营报表,结算管理，客服管理等。
          </li>
          <dd class="col">平台地址：<a href="https://service.xiaopenglife.com/#/login" target="_blank">点击查看</a></dd>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          小朋精选
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：App</dd>
            <dd class="col-5">技术架构：ReactNative</dd>
          </dl>
          <li>
            小朋精选是鹏博士电信传媒集团旗下基于社区的O2O购物平台，通过长城宽带覆盖全国2000个营业网点，为社区周边的人们提供便利快捷的购物服务，该平台还为长城宽带会员提供宽带查询、续费、报装、故障申报等服务。
          </li>
          <dd class="col">下载地址：<a href="https://apps.apple.com/cn/app/id1308191405" target="_blank">AppStore</a> | <a
              href="https://www.wandoujia.com/apps/7679883" target="_blank">豌豆荚</a></dd>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          e万家
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：App</dd>
            <dd class="col-5">技术架构：Android</dd>
          </dl>
          <li>
            此app是华润万家在线购物商城,连接全国各大门店，实现同城到店自提和全国配送。自上线以来累计用户量50万以上。
          </li>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          华润通
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：App</dd>
            <dd class="col-5">技术架构：Android</dd>
          </dl>
          <li>
            此app是一款由华润集团打造的手机生活应用。华润通app致力于为用户提供多样化的产品和服务，倡导品质、便利、有趣味的生活方式
          </li>
          <dd class="col">下载地址：<a href="https://a.app.qq.com/o/simple.jsp?pkgname=com.crc.hrt" target="_blank">应用宝</a>
          </dd>
        </dl>
      </div>
      <div class="base-info my-3">
        <div class="py-1 px-2 title mb-3">
          全国汽车票
        </div>
        <dl class="mr-3 ml-3">
          <dl class="row">
            <dd class="col">类型：App</dd>
            <dd class="col-5">技术架构：Android</dd>
          </dl>
          <li>
            该app上购买全国大部分城市的汽车票，目前除了购买车站的汽车票之外，还经营一些城际，旅游，机场，港澳大巴专线票务，亦可以包车。目前累计用户已经达到370多万。项目中用到了地图，第三方登录，第三方支付，消息推送，二维码条码生成和扫描等。
          </li>
        </dl>
      </div>
    </div>
  </main>
</template>

<script>
import Banner from '../../components/Home/Banner'
import Service from '../../components/Home/Service'
import Industry from '../../components/Home/Industry'
import Cooperate from '../../components/Home/Cooperate'
import Cases from '../../components/Home/Cases'
import Customer from '../../components/Home/Customer'
import News from '../../components/Home/News'
import FriendLink from '../../components/Home/FriendLink'
import Footer from '../../components/Footer'

export default {
  name: "Experience",
  components: {Banner, Service, Industry, Cooperate, Cases, Customer, News, FriendLink, Footer}
}
</script>

<style scoped lang="less">
.base-info {
  font-size: 13px;
  color: #333;
  line-height: 1.7;
}

.title {
  font-size: 15px;
  color: #284967;
  background-color: rgba(40, 73, 103, 0.12);
  font-weight: 700;
}
</style>

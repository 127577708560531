import axios from "axios";
import {isNullOrEmpty} from "../utils";

axios.defaults.timeout = 20000;
axios.defaults.maxContentLength = 1048576;
axios.defaults.headers["Accept"] = "application/json, text/plain, */*";
axios.defaults.headers["Content-Type"] = "application/json";

axios.interceptors.request.use(async function (config) {
    if (!isNullOrEmpty(window.token)) {
        config.headers["authorization"] = window.token;
    }
    config.headers["source"] = 'h5';
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    const {data} = response;
    // console.log('接口返回成功',response)
    if (data.errno == 0) {
        return Promise.resolve(data);
    }  
    let formatData = Object.assign(data, {msg: data.message});
    return Promise.reject(formatData);

}, async function (err) {
    // logger("响应失败", JSON.stringify(err));
    if (err.response) {
        // 请求已发出，但服务器响应的状态码不在 2xx 范围内
        const {data, status, statusText} = err.response;
        console.log("响应失败", status);
        if (status == 401 || status == 403) {
            // store.commit('tabs/ACCOUNT_LOGOUT_FAILURE', true)
            return Promise.reject(data);
        } else {
            let formatData = Object.assign(data, {message: statusText});
            return Promise.reject(formatData);
        }
    } else {
        const errorObj = {message: err.message || "服务器繁忙，请稍后重试"};
        return Promise.reject(errorObj);
    }
});
export default axios;

<template>
  <div>
    <div class="about-warp padding60 abt-bg">
      <div class="w1000">
        <div class="Title-group">
          <h2 class="title-sub">关于我们</h2>
          <span style="color: white!important;">About Us</span>
          <p class="sub-tit">“湖南峰行科技有限公司”</p>
        </div>
        <div class="abt-content">
          <article id="post-7" class="post-7 page type-page status-publish hentry">
            <div class="entry-content">
              <div>
                <span
                    class="about_title">&nbsp;&nbsp;&nbsp;&nbsp;湖南峰行科技有限公司（FENGXING）成立于2021年，致力于为全球企业提供全面互联网技术解决方案，包括</span>网站建设、APP应用定制开发、微信二次开发、微信小程序开发，移动手机APP开发、<span
                  class="about_title">互联网营销推广服务，是国内互联网行业值得信赖的品牌企业。</span>
              </div>
              <div class="tel about_sub_title">
                &nbsp;&nbsp;&nbsp;&nbsp;峰行的创始人及核心成员来自华润、鹏博士、腾讯、微软、华为等知名企业，拥有丰富的项目管理经验以及过硬的技术实力。
              </div>
              <div class="tel about_sub_title">
                &nbsp;&nbsp;&nbsp;&nbsp;目前，峰行的客户遍布全球多个地区和国家。赢得了客户信任的同时，峰行也赢得了外包开发行业的良好口碑。
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
    <div class="about-warp">
      <div class="w1000 padding60 skill-bg">
        <div class="Title-group">
          <h2 class="title-sub c-black">我们擅长的</h2>
          <span>Our Skill</span>
        </div>
        <div class="skill-list">
          <dl class="sk-1">
            <dt>产品设计 / UI设计 / 用户体验</dt>
            <dd><span style="width:98%"></span></dd>
          </dl>
          <dl class="sk-4">
            <dt>安卓客户端开发 / iOS客户端开发</dt>
            <dd><span style="width:94%"></span></dd>
          </dl>
          <dl class="sk-3">
            <dt>服务端开发 / Spring boot微服务 / Java / MySql / Linux</dt>
            <dd><span style="width:94%"></span></dd>
          </dl>
          <dl class="sk-2">
            <dt>网站开发 / HTML5 / CSS / 微信小程序</dt>
            <dd><span style="width:93%"></span></dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="about-warp padding60 bg-gray">
      <div class="w1000">
        <div class="Title-group">
          <h2 class="title-sub c-black">我们的团队</h2>
          <span>Our Team</span>
          <p>峰行<br/>
            坚持以客户为中心，认真做好每一个项目！</p>
        </div>
        <div class="us-team"><a href="http://wpa.qq.com/msgrd?v=3&uin=3269861047&site=qq&menu=yes"
                                title="通过QQ在线联系" class="cbqq">通过QQ在线联系</a>
          <p>We Look Forward To Your Contact ！</p>
        </div>
      </div>
    </div>

    <div class="contact-warp mapbg">
      
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutContent"
}
</script>

<style scoped lang="less">
.abt-bg {
  background-image: url("../../assets/images/abt-bg.jpg");
  background-position: center top;
  background-size: cover;
  padding-bottom: 10px;
}

.abt-content {
  color: white;
  font-size: 12px;
  margin: 0px 15px;
}

.skill-list {
  padding: 0px 15px;
}

.skill-list dl {
  margin: 20px 0;
  width: 100%;
  padding-left: 60px;
  height: 50px;
  overflow: hidden;
}

.skill-list dl dt {
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  color: #666;
  white-space: nowrap;
}

.skill-list dl dd {
  margin: 2px 0;
  height: 16px;
  background: #f0f0f0;
  border-radius: 8px;
}

.skill-list dl dd span {
  display: block;
  height: 16px;
  background-color: #0A71FF;
  border-radius: 8px;
}

.skill-list dl.sk-1 {
  background: url("../../assets/images/skill_bgs.png") no-repeat 0px 0px;
}

.skill-list dl.sk-2 {
  background: url("../../assets/images/skill_bgs.png") no-repeat 0px -50px;
}

.skill-list dl.sk-3 {
  background: url("../../assets/images/skill_bgs.png") no-repeat 0px -100px;
}

.skill-list dl.sk-4 {
  background: url("../../assets/images/skill_bgs.png") no-repeat 0px -150px;
}

.us-team {
  background: url("../../assets/images/us_img.png") no-repeat center top;
  padding: 280px 0 40px 0;
  font-size: 20px;
  color: #888;
  text-align: center;
  p{
    font-size: 14px;
  }
}

.contact-warp {
  min-width: 980px;
  width: 100%;
}

.mapbg {
  display: none;
}

.cbqq {
  display: block;
  margin: 0 auto;
  text-indent: -9999px;
  width: 71px;
  height: 43px;
  background: url("../../assets/images/cbqq.png") no-repeat 0px 0px;
  background-size: 50%
}

.cbqq:active {
  background: url("../../assets/images/cbqq.png") no-repeat 0px -42px;
  background-size: 50%
}

.Title-group p {
  line-height: 30px;
  font-size: 12px;
  color: #121212;
}
.sub-tit{
  color: white !important;
}

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .about_title {
      font-family: PingFangSC-Light, 'helvetica neue', 'hiragino sans gb', arial, 'microsoft yahei ui', 'microsoft yahei', Regular, simsun, sans-serif;
      font-size: 14px;
      line-height: 21px;
      text-align: justify;
    }

    .about_sub_title {
      margin: 5px 0px 0px;
      padding: 0px;
      border: 0px;
      font-stretch: inherit;
      line-height: 21px;
      font-family: PingFangSC-Light, 'helvetica neue', 'hiragino sans gb', arial, 'microsoft yahei ui', 'microsoft yahei', Regular, simsun, sans-serif;
      font-size: 14px;
      vertical-align: baseline;
      outline: none;
      box-sizing: border-box;
      -webkit-appearance: none;
      text-align: justify;
    }

    .about-warp {
      width: 100%;
    }

    .abt-bg {
      background-image: url("../../assets/images/abt-bg.jpg");
      background-position: center top;
      background-size: cover;
      Background-attachment: fixed;
    }

    .abt-content {
      margin: 0 auto;
      width: 1000px;
      padding: 20px 0;
      font-size: 16px;
      line-height: 30px;
      color: #fff;
    }

    .abt-content header, .project-form header {
      display: none;
    }

    .abt-content p {
      margin: 0 0 20px 0;
    }

    .skill-bg {
      background: url("../../assets/images/skill_bg.png") no-repeat right center;
    }

    .skill-list dl {
      margin: 20px 0;
      width: 50%;
      padding-left: 70px;
      height: 50px;
      overflow: hidden;
    }

    .skill-list dl dt {
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      color: #666;
    }

    .skill-list dl dd {
      margin: 2px 0;
      height: 16px;
      background: #f0f0f0;
      border-radius: 8px;
    }

    .skill-list dl dd span {
      display: block;
      height: 16px;
      background-color: #0A71FF;
      border-radius: 8px;
    }

    .skill-list dl.sk-1 {
      background: url("../../assets/images/skill_bgs.png") no-repeat 0px 0px;
    }

    .skill-list dl.sk-2 {
      background: url("../../assets/images/skill_bgs.png") no-repeat 0px -50px;
    }

    .skill-list dl.sk-3 {
      background: url("../../assets/images/skill_bgs.png") no-repeat 0px -100px;
    }

    .skill-list dl.sk-4 {
      background: url("../../assets/images/skill_bgs.png") no-repeat 0px -150px;
    }

    .us-team {
      background: url("../../assets/images/us_img.png") no-repeat center top;
      padding: 260px 0 40px 0;
      font-size: 20px;
      color: #888;
      text-align: center;
      p{
        font-size: 1rem;
      }
    }

    .contact-warp {
      min-width: 980px;
      width: 100%;
    }

    .mapbg {
      height: 520px;
      border-top: 1px solid #ddd;
      background-image: url("../../assets/images/map_bg.jpg");
      background-repeat: no-repeat;
      background-position: center;
      display: block;
    }

    .cbqq {
      display: block;
      margin: 0 auto;
      text-indent: -9999px;
      width: 152px;
      height: 184px;
      background: url("../../assets/images/cbqq.png") no-repeat 0px 0px;
    }

    .cbqq:hover {
      background: url("../../assets/images/cbqq.png") no-repeat 0px -184px;
    }

    .contact-detail {
      width: 100%;
      overflow: hidden;
    }

    .contact-detail li {
      float: left;
      width: 24%;
      margin: 0.5%;
      padding: 40px 0 168px 0;
    }

    .contact-detail li a {
      display: block;
      margin-right: 20px;
      text-indent: -9999px;
      float: left;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 1);
      -webkit-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      -moz-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    }

    .contacnt-address {
      padding: 170px 0 0 0;
      margin: 0 auto 60px;
      font-size: 16px;
      color: #f2f2f2;
      text-align: center;
    }

    .contacnt-address em {
      font-size: 20px;
      line-height: 50px;
      color: #CCC;
    }
  }
}

</style>

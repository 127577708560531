<template>

  <div class="service-container">
    <div class="new_module_top">
      <h2>合作流程</h2>
      <h4>— PROCESS —</h4>
      <p>专业的项目管理流程，为每个项目保驾护航。保证项目按时，保质的交付</p>
    </div>
    <ul class="new_cooperate_list">
      <li class="fl cooperate_item">
        <h3><img src="../../assets/images/new_index_cooperate_icon1.png" alt=""/>需求沟通</h3>
        <p>了解需求 | 需求分析 | 竞品分析</p>
      </li>
      <li class="fl cooperate_item">
        <h3><img src="../../assets/images/new_index_cooperate_icon2.png" alt=""/>方案制定</h3>
        <p>功能明细 | 技术方案 | 项目规划</p>
      </li>
      <li class="fl cooperate_item">
        <h3><img src="../../assets/images/new_index_cooperate_icon3.png" alt=""/>签订合同</h3>
        <p>功能报价 | 合同签订 | 时间计划</p>
      </li>
      <li class="fl cooperate_item">
        <h3><img src="../../assets/images/new_index_cooperate_icon4.png" alt=""/>产品设计</h3>
        <p>原型设计 | 交互设计 | UI设计</p>
      </li>
      <li class="fl cooperate_item">
        <h3><img src="../../assets/images/new_index_cooperate_icon5.png" alt=""/>程序开发</h3>
        <p>前端开发 | 后端开发 | 接口对接</p>
      </li>
      <li class="fl cooperate_item">
        <h3><img src="../../assets/images/new_index_cooperate_icon6.png" alt=""/>全面测试</h3>
        <p>单元测试 | 功能测试 | 系统测试</p>
        <p>安全测试 | UAT测试</p>
      </li>
      <li class="fl cooperate_item">
        <h3><img src="../../assets/images/new_index_cooperate_icon7.png" alt=""/>发布上线</h3>
        <p>域名备案 | 服务器部署 | 软著申请</p>
        <p>提交上架审核</p>
      </li>
      <li class="fl cooperate_item">
        <h3><img src="../../assets/images/new_index_cooperate_icon8.png" alt=""/>售后服务</h3>
        <p>日常维护 | 技术支持 | BUG修复</p>
        <p>版本更新</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Cooperate",
}
</script>

<style scoped lang="less">
.new_cooperate_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  @iterations: 8;
  .li-loop (@i) when (@i > 0) {
    li:nth-child(@{i}) {
      background-image: url('../../assets/images/new_index_cooperate_bg@{i}.png');
      background-size: cover !important;
      background-repeat: no-repeat;
      background-position: 100% 100%;
    }
    .li-loop(@i - 1);
  }
  .li-loop (@iterations);

  li {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    width: 45%;
    padding:  20px;
    flex-direction: column;
    border: 1px solid #e7ecf2;
    border-radius: 6px;

    h3 {
      align-items: center;
      color: #333;
      display: flex;
      font-size: 16px;
      justify-content: flex-start;
      margin-bottom: 15px;

      img {
        height: 22px;
        margin-right: 5px;
        object-fit: contain;
        width: 22px;
      }
    }

    p {
      color: #666;
      font-size: 12px;
      line-height: 20px;
    }
  }
}

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .new_cooperate_list {
      width: 100%;

      li {
        width: 22.5%;
        background-position: 100% 100%;
        background-size: auto !important;
        padding: 20px 30px;
        h3 {
          align-items: center;
          color: #333;
          display: flex;
          font-size: 24px;
          justify-content: flex-start;
          margin-bottom: 15px;

          img {
            height: 45px;
            margin-right: 5px;
            object-fit: contain;
            width: 45px;
          }
        }

        p {
          color: #666;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}

</style>

<template>
  <div class="page-container">
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Footer from "../../components/Footer"

export default {
  name: "Cases",
  components: {Footer}
}
</script>

<style scoped lang="less">
.footer-container {
  display: none;
}

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .footer-container {
      display: block;
    }
  }
}
</style>

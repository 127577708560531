<template>
  <header>
    <!-- Fixed navbar -->
    <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">李俊峰</a>
        <button class="navbar-toggler collapsed"
                type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse" 
                aria-expanded="false"
                v-on:click="toggleNavbar"
                aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div v-bind:class="{ in: show }" class="navbar-collapse collapse" id="navbarCollapse" style="">
          <ul class="navbar-nav me-auto mb-2 mb-md-0">
            <!--            <li class="nav-item">-->
            <!--              <a class="nav-link active" aria-current="page" href="/">个人简历</a>-->
            <!--            </li>-->
            <!--            <li class="nav-item">-->
            <!--              <a class="nav-link" href="/experience">项目经验</a>-->
            <!--            </li>-->

            <li v-for="item in menu" class="nav-item">
              <router-link class="nav-link" replace :exact="item.linkTo=='/'" active-class="active" :to="item.linkTo"
                           @click.native="toggleNavbar">{{ item.title }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>

import NativeShare from 'nativeshare'

export default {
  name: "WebHeader",
  data() {
    return {
      menu: [
        {
          linkTo: '/',
          title: '个人简介',
        },
        {
          linkTo: '/experience',
          title: '项目经验',
        },
      ],
      showMenu: false,
      show: true
    }
  },
  mounted() {
    this.nativeShare = new NativeShare()
    // 设置分享文案
    this.nativeShare.setShareData({
      icon: 'https://pic3.zhimg.com/v2-080267af84aa0e97c66d5f12e311c3d6_xl.jpg',
      link: 'https://www.fxcxy.com',
      title: '峰行科技',
      desc: '峰行科技(fxcxy)是一家专业做手机应用软件开发与服务的公司，业务涵盖APP开发、手机APP软件定制开发、app开发外包、APP运营、微信商城开发、微信小程序开发、APP定制开发等',
    })
  },
  methods: {
    toggleNavbar() {
      console.log('点击菜单')
      this.show = !this.show;
    },
    changeMenuVisible() {
      
      this.showMenu = !this.showMenu;
    },
    shareWeb() {
      if (navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1) {
        this.$toast.info('图文分享请打开QQ浏览器')
      } else {
        try {
          console.log('此浏览器不支持跳转', this.nativeShare)
          this.nativeShare.call()
        } catch (err) {
          console.log('此浏览器不支持跳转', err)
          this.$toast.info('此浏览器不支持跳转', {position: 'bottom', duration: 1000})
        }
      }
    },
    beforeRouteUpdate(to, from, next) {

      if (to.path != from.path) {
        this.id = to.params.id;
      }
      next()  // 一定要有next
    }
  },
}
</script>

<style scoped lang="less">

.top-container {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9999;
}

.top-bar-wrapper {
  background: #0A71FF;
}

.top-bar {
  height: 48px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.menu-icon {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

// 设置菜单
.mobile-nav {
  position: fixed;
  top: 48px;
  left: 0;
  bottom: 0;
  right: 0;
  // 设置背景颜色
  background-color: #000000cc;
  padding-top: 60px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline-start: 0px;
  margin-bottom: 0;

  li {
    width: 80%;
    margin: 0 auto;
    border-bottom: 0.5px solid #f7f7f780;

    a {
      display: block;
      line-height: 44px;
      font-size: 14px;
      color: white;
    }

    &:last-child a {
      display: inline-block;
      margin-right: 6px;
    }

    span {
      color: #fff;
      font-size: 14px;
    }
  }

}


// 设置左侧的导航
.left-menu:extend(.menu-icon) {
}

.new_active {
  border-bottom-color: #0a71ff !important;
  color: #0a71ff !important;
  font-weight: bold !important;
}

//设置logo
.logo {
  a {
    display: block;

    img {
      width: 150px;
      height: 30px;
    }
  }
}

.mobile-title {
  font-size: 18px;
  color: white;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

// 设置右侧的分享
.mobile-share:extend(.menu-icon) {

}

.new_left_tips, .new_right_links, .new_header_nav {
  display: none;
}

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .top-container {
      position: unset;
    }

    .left-menu, .mobile-title, .mobile-share {
      display: none;
    }

    .top-bar {
      max-width: 1200px;
      margin: 0 auto;

      .new_left_tips, .new_right_links {
        display: flex;
        color: #fff;
        font-size: 14px;
        align-items: center;
      }

      .new_right_links {
        p {
          margin-bottom: 0;
          vertical-align: center;

          img {
            margin-right: 5px;
            object-fit: contain;
            width: 20px;
          }
        }

        a {
          color: #fff;
          display: block;
          line-height: 40px;
          text-decoration: none;
        }
      }
    }

    .new_header_nav {
      max-width: 1200px;
      display: flex;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;

      .new_left_logo {
        a {
          img {
            width: 150px;
          }
        }
      }

      .new_right_nav {
        display: flex;

        li {
          height: 80px;
          margin: 0 20px;

          a {
            border-bottom: 2px solid transparent;
            color: #333333;
            font-size: 16px;
            line-height: 78px;
            font-weight: normal;
            text-decoration: none;
            display: block;

            &:active, &:hover {
              border-bottom-color: #0a71ff;
              color: #0a71ff;
              font-weight: normal;
            }
          }
        }
      }
    }
  }

}


</style>

<template>
  <div>
    <CaseTypes />
    <!--手机版-->
    <div class="mobile-container">
      <article v-for="item in mCasesList" :key="item.id">
        <div class="mobile-item">
          <router-link :to="`/case/details/${item.id}`" :title="item.name">
            <img :src="getImage(item.imgPath)" :alt="item.name"
                 class="attachment-post-thumbnail wp-post-image"
            />
            <span></span>
          </router-link>
          <div class="mobile-text">
            <router-link :to="`/case/details/${item.id}`" :title="item.name">
              <h4>{{ item.name }}</h4>
            </router-link>
            <p>
              <span><router-link :to="`/case/list/${item.projectTypeId}`">{{ item.projectType }}</router-link></span>
              <em><label>{{ item.pageviews }}</label>浏览</em>
            </p>
          </div>
        </div>
      </article>
      <div style="overflow: auto;">
        <infinite-loading force-use-infinite-wrapper=".mobile-container" @infinite="infiniteHandler" :distance="200"
                          ref="infiniteLoading">
          <span slot="no-more">没有更多数据</span>
          <span slot="no-results">没有更多数据</span>
        </infinite-loading>
      </div>
    </div>
    <!--PC版-->
    <div class="case-warp bg-gray padding60">
      <div class="w1000">
        <div class="case-list" id="content">
          <article v-for="item in casesList" :key="item.id">
            <div class="citem">
              <router-link :to="`/case/details/${item.id}`" :title="item.name">
                <img :src="getImage(item.imgPath)" :alt="item.name"
                     class="attachment-post-thumbnail wp-post-image"
                />
                <span></span>
              </router-link>
              <div class="citemtxt">
                <router-link :to="`/case/details/${item.id}`" :title="item.name">
                  <h4>{{ item.name }}</h4>
                </router-link>
                <p>
                  <span><router-link :to="`/case/list/${item.projectTypeId}`">{{
                      item.projectType
                    }}</router-link></span>
                  <em><label>{{ item.pageviews }}</label>浏览</em>
                </p>
              </div>
            </div>
          </article>
        </div>
      </div>
      <Pagination :totalPage="pageTotal" :changePageNumber="changePageNumber"/>
    </div> 
  </div>
</template>

<script>
import cases from "../../data/cases.json"
import Pagination from "../../components/Pagination";
import InfiniteLoading from 'vue-infinite-loading';
import {isNullOrEmpty} from "../../utils"
import CaseTypes from "../../components/Cases/CaseTypes";

export default {
  name: "CaseList",
  components: {Pagination, InfiniteLoading,CaseTypes},
  data() {
    return {
      cases,
      pageSize: 12,
      pageTotal: 0,
      casesList: [],
      mCasesList: [],
      pageParams: {
        pageNo: 1,
        typeId: this.$route.params.id,
      },
      mPageNo: 1,
      mTypeId: this.$route.params.id,
    }
  },
  methods: {
    getImage(imgName) {
      return require('../../assets/images/' + imgName)
    },
    changePageNumber(value) {
      this.pageParams.pageNo = value;
    },
    infiniteHandler($state) {
      this.mPageNo++
      // console.log('加载更多回调', this.mPageNo, this.pageTotal, $state)

      if (this.mPageNo < this.pageTotal) {
        //加载更多
        const tempList = this.pageParams.typeId === 'all' ? cases : cases.filter((data) => data.projectTypeId === this.pageParams.typeId)
        const dataList = tempList?.filter((data, index) => index < this.mPageNo * this.pageSize && index >= (Number(this.mPageNo) - 1) * this.pageSize)
        if (this.mPageNo == 1) {
          this.mCasesList = [...dataList]
          $state.loaded();
        } else {
          if (isNullOrEmpty(dataList)) {
            $state.complete();
          } else {
            this.mCasesList.push(...dataList)
            $state.loaded();
          }
        } 
      } else {
        //没有更多数据
        $state.complete();
      }
    }
  },
  watch: {
    pageParams: {
      immediate: true, //初始化时让handler调用一下
      deep: true,//深度监视
      handler(pageParams, oldValue) {
        const tempList = pageParams.typeId === 'all' ? cases : cases.filter((data) => data.projectTypeId === pageParams.typeId)
        this.pageTotal = Math.ceil(tempList.length / this.pageSize)
        const dataList = tempList?.filter((data, index) => index < pageParams.pageNo * this.pageSize && index >= (Number(pageParams.pageNo) - 1) * this.pageSize);
        this.casesList = dataList
        if (pageParams.pageNo == 1) {
          this.mCasesList = dataList
        }
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path != from.path) {
      this.pageParams.typeId = to.params.id;
      this.pageParams.pageNo = 1;
      this.mPageNo = 1;
      // console.log('路由变更')
      this.$refs.infiniteLoading.stateChanger.reset()
    }
    next()  // 一定要有next
  }

}
</script>

<style scoped lang="less">
.case-warp {
  display: none;
}

.infinite-status-prompt {
  span {
    font-size: 12px;
    color: #888;
  }
}


.mobile-container {
  background: #f8f8f8;
}

.mobile-item {
  display: flex;
  background: white;
  flex-direction: column;
  width: 96%;
  margin: 0 auto 15px;
  box-shadow: 0 0 10px 0 rgb(53 67 78 / 8%);
  border-radius: 6px;

  img {
    display: block;
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
}

.mobile-text {
  padding: 10px;

  a {
    display: block;

    h4 {
      color: #333;
      font-size: 14px;
      line-height: 40px;
      margin: 0;
      overflow: hidden;
      padding: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  p {
    display: flex;
    justify-content: space-between;
    color: #999;
    font-size: 12px;
  }
}

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .mobile-container {
      display: none;
    }

    .case-warp {
      display: block;
    }

    .case-list {
      margin-left: -0.8333%;
      margin-right: -0.8333%;
      overflow: hidden;
    }

    .case-list li, .case-list article {
      float: left;
      width: 23.3333%;
      margin: 0.8333%;
      background: #fff;
    }

    .citem a {
      position: relative;
      display: block;
      text-decoration: none;
    }

    .citem a span {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0);
      background-position: top center;
      background-repeat: no-repeat;
      -webkit-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      -moz-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    }

    .citem a img {
      display: block;
      width: 100%;
      height: 64.2857%;
    }

    .citem a:hover span {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.87);
      background-image: url("../../assets/images/see_bg.png");
      background-repeat: no-repeat;
      background-position: center center;
    }

    .citemtxt {
      padding: 3.571429% 7.1429%;
    }

    .citemtxt a {
      text-decoration: none;
      -webkit-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      -moz-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    }

    .citemtxt a h4 {
      margin: 0;
      padding: 0;
      color: #666;
      font-size: 14px;
      line-height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .citemtxt a:hover h4 {
      color: #0A71FF;
    }

    .citemtxt p {
      overflow: hidden;
      text-align: right;
      font-size: 12px;
      line-height: 20px;
      color: #999;
    }

    .citemtxt p span {
      float: left;
      width: auto;
      text-align: left;
      width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .citemtxt p span a {
      color: #0A71FF;
    }

    .citemtxt p a {
      display: inline-block;
      color: #999;
    }
  }
}
</style>

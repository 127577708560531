<template>
  <div class="new_index_customer">
    <div class="new_container">
      <div class="service-container">
        <div class="new_module_top">
          <h2>我们的客户</h2>
          <h4>— CUSTOMERS —</h4>
          <p>始终坚持以客户为中心，帮助客户成功是我们存在的唯一价值</p>
        </div>
        <div class="new_customer_list">
          <ul class="clearfix">
            <li v-for="(data,index) in customers" class="fl" :key="index">
              <a :href="data.url" target="_blank">
                <img :src="getImage(`new_index_customers${index + 1}.png`)"
                     :alt="data.name"/>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import customers from "../../data/customers.json"

export default {
  name: "Customer",
  data() {
    return {customers}
  },
  methods: {
    getImage(imgName) {
      return require('../../assets/images/' + imgName)
    }
  },
}
</script>

<style scoped lang="less">
.new_customer_list {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 25px 0 rgb(51 82 133 / 15%);
  padding: 20px;
  width: 90%;
  margin: 20px auto 0;

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 50%;

      a {
        padding: 20px 15px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border: 1px solid #ebf0f4;
        border-bottom: 0px;

        img {
          width: 100%;
          -o-object-fit: contain;
          object-fit: contain;
        }
      }

      a:active {
        -webkit-box-shadow: 0px 5px 15px 0px rgba(51, 82, 133, 0.15);
        box-shadow: 0px 5px 15px 0px rgba(51, 82, 133, 0.15);
      }
    }

    li:nth-child(even) {
      a {
        border-left: 0px;
      }
    }

    li:last-child {
      a {
        border-bottom: 1px solid #ebf0f4;
      }
    }

    li:nth-child(9) {
      a {
        border-bottom: 1px solid #ebf0f4;
      }
    }
  }
}

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .new_index_customer {
      height: 460px;
      background: url("../../assets/images/new_index_customer_bg.jpg") no-repeat;
      background-size: cover;
      margin-bottom: 200px;

      .new_module_top {
        h4 {
          color: #afdbff;;
        }

        h2,p {
          color: white;
        }
      }
    }

    .new_customer_list {
      margin-top: 50px;
      padding: 50px;
      width: 1200px;
      background: #fff;
      -webkit-box-shadow: 0px 10px 25px 0px rgba(51, 82, 133, 0.15);
      box-shadow: 0px 10px 25px 0px rgba(51, 82, 133, 0.15);
      border-radius: 10px;

      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          width: 20%;

          a {
            padding: 40px 25px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            border: 1px solid #ebf0f4;
          }

          a:hover {
            -webkit-box-shadow: 0px 5px 15px 0px rgba(51, 82, 133, 0.15);
            box-shadow: 0px 5px 15px 0px rgba(51, 82, 133, 0.15);
          }
        }

        li:nth-child(even) {
          a {
            border-left: 1px solid #ebf0f4;
          }
        }
      }
    }
  }
}

</style>

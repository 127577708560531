<template>
  <div>
    <!--手机版-->
    <div class="mobile-main">
      <div class="details-nav">您当前的位置:<a
          href='/'>长沙APP开发</a> > <a href='/news'>新闻资讯</a> >
        <router-link
            :to="`/news/${details.typeId}`">{{ details.typeName }}
        </router-link>
        >
      </div>
      <div class="details-title">
        <h1>{{ details.title }}</h1>
        <div class="zuozhe">来源：fxcxy.com 发布时间：{{ details.date }}</div>
      </div>
      <div class="details-content">
        <div  v-html="details.content"/>
      </div>
      <div class="details-bottom">
        <ul class="next">
          <li v-show="prev" @click="jumpToPage(prev)">上一篇：
            {{ prev && prev.title }}
          </li>
          <li v-show="next" @click="jumpToPage(next)">下一篇：
            {{ next && next.title }}
          </li>
        </ul>
      </div>
    </div>
    <!--pc版-->
    <div class="main1 clearfix">
      <div class="mainn">
        <div class="mleft paddingb10">
          <div class="right_nav">您当前的位置:<a
              href='/'>长沙APP开发</a> > <a href='/news'>新闻资讯</a> >
            <router-link
                :to="`/news/${details.typeId}`">{{ details.typeName }}
            </router-link>
            >
          </div>
          <div class="leftnr">
            <div class="yuedu">
              9000人<br/>已阅读
            </div>
            <div class="leftbt">
              <h1>{{ details.title }}</h1>
              <div class="zuozhe">来源：fxcxy.com 发布时间：{{ details.date }}</div>
            </div>
          </div>
          <div class="left_content">
            <div v-html="details.content"/>
          </div>
          <div class="left_xx"></div>
          <div style="clear: left">
            <ul class="next">
              <li v-show="prev" @click="jumpToPage(prev)">上一篇：
                {{ prev && prev.title }}
              </li>
              <li v-show="next" @click="jumpToPage(next)">下一篇：
                {{ next && next.title }}
              </li>
            </ul>
          </div>
        </div>
        <NewsCase/>
      </div>
    </div>
  </div>
</template>
<script>
import news from "../../data/news.json"
import NewsCase from "../../components/News/NewsCase";

export default {
  name: "NewsDetails",
  components: {NewsCase},
  data() {
    return {
      prev: null,
      next: null,
      details: {},
      id: this.$route.params.id,
    }
  },
  methods: {
    getImage(imgName) {
      return require('../../assets/images/' + imgName)
    },
    jumpToPage(obj) {
      obj && this.$router.push({
        name: 'newsDetails',
        params: {
          id: obj.id
        }
      })
    }
  },
  watch: {
    id: {
      immediate: true, //初始化时让handler调用一下
      handler(value, oldValue) {
        this.details = news.find(data => data.id === value)
        const currentIndex = news.findIndex(data => data.id === value)
        console.log('详情', this.details)

        if (currentIndex > 0) {
          this.prev = news[currentIndex - 1]
        } else {
          this.prev = null
        }
        if (currentIndex < news.length - 1) {
          this.next = news[currentIndex + 1]
        } else {
          this.next = null
        }
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path != from.path) {
      this.id = to.params.id;
    }
    next()  // 一定要有next
  }

}
</script>

<style scoped lang="less">
.main1 {
  display: none;
}

.mobile-main {
  display: flex;
  flex-direction: column;
  padding: 10px;
  font-size: 12px;
  overflow-y: hidden;
}

.details-nav {
  a {
    color: #121212;
  }

  a:active {
    color: #0A71FF;
  }
}
.details-title{
  h1{
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
  }
  .zuozhe{
    text-align: center;
    margin-bottom: 10px;
  }
}
.details-content /deep/ img{ 
  width: 100% !important;
  height: auto!important;
  margin:20px auto;
}

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .mobile-main {
      display: none;
    }

    .main1 {
      margin: 20px auto;
      overflow: hidden;
      display: block;
    }

    .mainn {
      margin: 0 auto;
      width: 1000px;
    }

    .right_nav {
      font-size: 12px;
      line-height: 30px;

      a {
        color: #121212;
      }

      a:hover {
        color: #0A71FF;
      }
    }

    .mleft {
      width: 680px;
      border: 1px solid #ebebeb;
      background-color: #FFF;
      float: left;
      padding: 20px 10px;
      box-sizing: content-box;
    }

    .clearfix::after, .clearfix::before {
      clear: both;
      content: '';
      display: table;
    }

    .leftnr {
      background: url("../../assets/images/biaoqian.jpg") no-repeat;
      border-bottom: 1px dashed #ebebeb;
      float: left;
      height: 100px;
      margin-top: 30px;
      width: 680px;
    }

    .leftnr .yuedu {
      color: rgb(255, 255, 255);
      float: left;
      font-family: 宋体;
      font-size: 12px;
      height: 70px;
      padding-left: 7px;
      padding-top: 5px;
      width: 53px;
    }

    .leftnr .leftbt {
      float: left;
      width: 620px;
      height: 30px;
    }

    .leftnr .leftbt h1 {
      float: left;
      width: 620px;
      height: 30px;
      line-height: 30px;
      font-size: 24px;
      text-align: center;
      color: #5c5c5c;
      font-weight: normal;
    }

    .leftnr .leftbt .zuozhe {
      float: left;
      width: 620px;
      height: 40px;
      font-size: 12px;
      text-align: center;
      color: #5c5c5c;
    }

    .left_xx {
      float: left;
      width: 630px;
      height: 1px;
      margin-left: 25px;
      margin-right: 25px;
      border-bottom: 1px dashed #ebebeb;
    }

    .left_content {
      float: left;
      width: 630px;
      padding: 0px 25px 20px 25px;
      line-height: 26px;
      font-family: "宋体";
      color: #5c5c5c;
      font-size: 14px;
    }

    .left_content p {
      padding-top: 20px;
    }

    .left_content .jianjie {
      float: left;
      font-size: 12px;
      font-weight: bold;
      text-indent: 2em;
      padding-top: 20px;
    }

    .left_content .jianjie a {
      color: #5c5c5c;
    }

    .left_content .tag {
      float: left;
      font-size: 12px;
      color: #868686;
    }

    .left_content .tag a {
      color: #5c5c5c;
    }

    .zxal p a {
      text-decoration: none;
      color: #333;
      font-size: 12px;
    }

    .next {
      padding-top: 10px;

      li {
        cursor: pointer;
        font-size: 14px;
      }

      li:hover {
        color: #0A71FF;
      }
    }
  }
}

</style>

<template>
  <div>
    <div class="service-warp bg-red padding60">
      <div class="w1000">
        <div class="Title-group">
          <h2 class="title-sub">我们的服务流程</h2>
          <span class="title-sub-t">Service Process</span>
          <p class="sub-tit">“专业的服务流程，保障您的项目高质快速交付！”</p>
        </div>
        <div class="process-map"></div>
      </div>
    </div>
    <div class="service-warp padding60">
      <div class="w1000">
        <div class="Title-group">
          <h2 class="title-sub c-black">我们的服务报价</h2>
          <span>Service Price</span>
        </div>
        <div class="see-btn-center">
          <a href="#!" rel="nofollow"
             @click="openChat()"
             class="contact-btn1">咨询报价 ></a>
        </div>
        <div class="tip-txt"> 峰行科技，真诚为您服务！</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceProcessPrice",
  methods:{
    openChat(){
      window.openChat()
    }
  }
}
</script>

<style scoped>

</style>

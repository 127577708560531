<template>
  <div class="item-container" @click="jumpToPage(data.id)">
    <h3>{{ data.title }}</h3>
    <span>{{ data.date }}</span>
    <div class="item-content">
      {{ summary }}
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "NewsItem",
  props: ['data'],
  data() {
    return {
      year: moment(this.data.date).format('YYYY'),
      date: moment(this.data.date).format('MM-DD'),
      summary: this.data.summary.substring(0, 80)
    }
  },
  methods: {
    jumpToPage(id) {
      this.$router.push({
        name: 'newsDetails',
        params: {
          id
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.item-container {
  display: flex;
  margin: 10px;
  flex-direction: column;
  border-bottom: 1px dashed #ebebeb;

  h3 {
    font-size: 16px;
  }

  span {
    font-size: 12px;
    color: #999;
  }

  .item-content {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
</style>

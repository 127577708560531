<template>
  <div>
    <div class="service-warp service-bg padding60">
      <div class="w1000">
        <div class="Title-group">
          <h2 class="title-sub">微信开发</h2>
          <span style="color: white!important;">WeChat Development</span>
          <p class="sub-tit">“微信深度定制开发 H5互动解决方案”</p>
        </div>
        <div class="service-item">
          <div class="sitem">
            <a href="#!">
              <div class="big-icon bg"><i class="fa"><img alt=""
                                                                  src="../../assets/images/wechattu1.png" width="96" height="96"/></i></div>
            </a>
            <h4><em>小程序<span>Applet</span></em></h4>
            <p><span>电商系统</span><span>预约服务</span><span>资讯热点</span><span>后台管理</span></p>
          </div>
          <div class="sitem">
            <a href="#!">
              <div class="big-icon bg"><i class="fa"><img alt=""
                                                                  src="../../assets/images/wechattu2.png" width="96" height="96"/></i></div>
            </a>
            <h4><em>公众号<span>WeChat</span></em></h4>
            <p><span></span><span>API中各类消息使用</span><span>业务系统对接</span><span>常见功能开发</span></p>
          </div>
          <div class="sitem">
            <a href="#!">
              <div class="big-icon bg"><i class="fa"><img alt=""
                                                                  src="../../assets/images/wechattu3.png" width="96" height="96"/></i></div>
            </a>
            <h4><em>H5小游戏<span>H5 Game</span></em></h4>
            <p><span>Web UI设计</span><span>开发周期短</span><span>无需下载软件</span><span>H5游戏接口稳定</span></p>
          </div>
          <div class="sitem"><a href="#!">
            <div class="big-icon bg"><i class="fa"><img alt=""
                                                                src="../../assets/images/wechattu4.png" width="96" height="96"/></i></div>
          </a>
            <h4><em>微信商城<span>Mall</span></em></h4>
            <p>
              <span>Wap 微信建站</span><span>Web(HTML5+CSS3)</span><span>IOS客户端开发</span><span>Android客户端开发</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="translucent-nav-block">
      <div class="translucent-nav-content">
        <h2 class="contact-title">微信二次开发</h2>
        <div class="contact-bar clearfix">
          <div class="contact-list">
            <img src="../../assets/images/wechat1.jpg" height="300" width="560" alt=""/>
            <div class="contact-popup">
              <div class="contact-popup-inner">
                <div class="inner-content">
                </div>
              </div>
            </div>
          </div>

          <div class="contact-list">
            <div class="contact-popup">
              <div class="contact-popup-inner">
                <div class="inner-content">
                  <div class="address-title">微信二次开发</div>
                  <div
                      class="tel">通过二次开发可以将公众账号由一个媒体型营销工具转化成提供服务的产品。而一旦成为用户需要的产品，公众账号的营销功能便会开启。微信的核心是通讯工具，这一工具属性将用户牢牢地黏在了平台之上。用户和企业可以非常方便地在上面进行沟通，所以微信很自然地就成了企业的CRM(客户管理系统)平台来面对忠实用户，这也给了企业将服务引入平台的机会。减少宣传成本，建立企业与消费者、客户的一对一互动和沟通，将消费者接入企业CRM系统，进行促销、推广、宣传、售后等。形成了一种主流的线上线下微信互动营销方式。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="translucent-nav-content">
        <h2 class="contact-title">主要模板</h2>
        <div class="contact-bar clearfix">
          <div class="contact-list">
            <div class="contact-popup">
              <div class="contact-popup-inner">
                <div class="inner-content">
                  <div class="address-title">微信开发主要模板</div>
                  <div class="tel">根据市场的需求，微信开发主要模板分为：<br/>
                    在线营销：向终端客户推荐新品、促销策略等；<br/>
                    售后服务：通过微信平台，自动与客户进行交互，展示企业产品、售后支持；<br/>
                    电子商务：将企业网站或购物平台与微信对接，推荐到朋友圈获取免费宣传；<br/>
                    会员管理：企业会员可通过微信平台进行线下活动预约、积分管理等操作。
                  </div>
                  <div class="tel"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="contact-list">
            <img src="../../assets/images/wechat2.jpg" height="300" width="560" alt=""/>
            <div class="contact-popup">
              <div class="contact-popup-inner">
                <div class="inner-content">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="translucent-nav-content">
        <h2 class="contact-title">主要功能</h2>
        <div class="contact-bar clearfix">
          <div class="contact-list">
            <img src="../../assets/images/app_web.jpg" height="300" width="560" alt=""/>
            <div class="contact-popup">
              <div class="contact-popup-inner">
                <div class="inner-content">
                </div>
              </div>
            </div>
          </div>

          <div class="contact-list">
            <div class="contact-popup">
              <div class="contact-popup-inner">
                <div class="inner-content">
                  <div class="address-title">微信开发主要功能</div>
                  <div class="tel">（一）微信官网
                    1、公司介绍：支持多级分类；
                    2、产品展示：产品支持多图显示，手指滑动浏览图片；
                    3、新闻资讯：对接微信公众平台消息推送；
                    4、联系方式：LBS地图位置标注，点击电话号码直接拨打；
                    5、信息推送：以微信官方规定的消息推送为标准。<br/>
                    （二）微信客服
                    1、多人工客服：实现多个人工客服在线与微信公众平台客户沟通；
                    2、LBS位置服务：用户经过微信提供位置，公众平台自动应答离用户最近的门店、经销商；
                    3、建议/投诉/售后：微信平台内嵌售后服务表单。<br/>
                    （三）微信商城
                    1、在线订购：支持现有商城系统进行对接；
                    2、会员系统：支持现有会员系统进行对接；
                    3、在线支付：支持在线支付功能，若无需在线支付，则只记录订单信息与流程。<br/>
                    （四）活动与促销
                    1、活动发布：软商系统直接对接微信平台发布；
                    2、刮刮卡：支持系统自定义奖品。
                    3、转盘：支持系统自定义奖品。
                  </div>
                  <div class="tel"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="translucent-nav-content">
        <h2 class="contact-title">开放接口</h2>
        <div class="contact-bar clearfix">
          <div class="contact-list">
            <div class="contact-popup">
              <div class="contact-popup-inner">
                <div class="inner-content">
                  <div class="address-title">开放接口</div>
                  <div class="tel">1、语音识别接口<br/>
                    2、客服接口<br/>
                    3、OAuth2.0 网页授权接口<br/>
                    4、生成带参数的二维码接口<br/>
                    5、获取用户地理位置接口<br/>
                    6、获取用户基本信息接口<br/>
                    7、获取关注者列表接口<br/>
                    8、用户分组接口<br/>
                    9、上传下载多媒体文件接口
                  </div>
                  <div class="tel"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="contact-list">
            <img src="../../assets/images/wechat4.jpg" height="300" width="560" alt=""/>
            <div class="contact-popup">
              <div class="contact-popup-inner">
                <div class="inner-content">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="translucent-nav-content">
        <h2 class="contact-title">微信开发价格</h2>
        <div class="contact-bar clearfix">
          <div class="contact-list">
            <img src="../../assets/images/wechat5.jpg" height="300" width="560" alt=""/>
            <div class="contact-popup">
              <div class="contact-popup-inner">
                <div class="inner-content">
                </div>
              </div>
            </div>
          </div>

          <div class="contact-list">
            <div class="contact-popup">
              <div class="contact-popup-inner">
                <div class="inner-content">
                  <div class="address-title">微信二次开发报价</div>
                  <div
                      class="tel">微信开发的价格，目前市场没有标准的价格，都是根据微信开发的行业、用途、功能复杂程度、开发周期、开发人员团队等因素来综合考虑，微信开发的成本包括：1、开发团队人员工资，
                    开发团队最少配有5个人，项目经理1人，前段开发1人，后端开发1人，UI1设计师人，测试人员1人，每个地方工资不一样，成本有所不同，按一个月来算工资成本在5万到7万;2、服务器租赁费用，除非是大企业，不然很少有公司自己组建服务器，
                    毕竟这个组建维护费用是相当高的，一般都是采取租赁第三方服务器的形式，而租赁费用是3000元/年。所以，微信开发费用大致在10万到50万不等。
                  </div>
                  <div class="tel"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ServiceProcessPrice/>
  </div>
</template>

<script>
import  ServiceProcessPrice from "../AppDev/ServiceProcessPrice"
export default {
  name: "AppletsContent",
  components:{ServiceProcessPrice}
}
</script>

<style scoped lang="less">
.translucent-nav-content{
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 15px;
  h2{
    align-self: center;
    font-size: 16px;
  }
}
.contact-list{
  img{
    width: 100%;
    height: auto;
    margin:10px 0px;
  }
}
//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .translucent-nav-block {
      padding-top: 100px;
      width: 100%;
      height: auto;
    }

    .translucent-nav-block .translucent-nav-content {
      width: 1200px;
      background-color: #FFF;
      margin: 20px auto 0;
      display: block;
      padding:0px;
      h2{
        display: block;
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
    }

    .contact-bar {
      margin-top: 16px;
      padding: 0 30px 58px;
    }

    .contact-bar .contact-list {
      float: left;
      width: 560px;
      height: 300px;
      margin: 0 5px;
      position: relative;
      overflow: hidden;
    }

    .contact-bar .contact-list .contact-popup {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
    }

    .contact-bar .contact-list .contact-popup .contact-popup-inner {
      width: 100%;
      height: 100%;
      display: table;
    }

    .contact-bar .contact-list .contact-popup .contact-popup-inner .inner-content {
      display: table-cell;
      vertical-align: middle;
      padding: 0 20px;
      color: #222222;
      font-size: 14px;
    }

    .contact-bar .contact-list .contact-popup .contact-popup-inner .inner-content .address-title {
      font-size: 18px;
      margin-bottom: 12px;
    }

    .contact-bar .contact-list .contact-popup .contact-popup-inner .inner-content .tel {
      margin-top: 5px;
    }
  }
}
</style>

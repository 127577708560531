<template>
  <div class="service-container">
    <div class="new_module_top">
      <h2>我们的服务</h2>
      <h4>— SERVICES —</h4>
      <p>为企业提供专业的APP软件开发、电商平台，收银系统，交友软件开发</p>
      <p>高端网站制作、小程序商城开发等服务，服务的国内外企业超过100家</p>
    </div>
    <!--    手机版-->
    <ul class="mobile-new_service_list">
      <li class="service_item">
        <img src="../../assets/images/new_index_service_icon1.png" alt=""/>
        <div>
        <h4>APP定制开发</h4>
        <p>ReactNative App开发</p>
        <p>原生iOS,安卓APP开发</p>
        <p>涵盖数十个行业数百个成功案例</p>
        </div>
      </li>
      <li class="service_item">
        <img src="../../assets/images/new_index_service_icon2.png" alt=""/>
        <div>
        <h4>高端网站制作</h4>
        <p>电商类网站开发</p>
        <p>高端企业官网设计与制作</p>
        <p>PC端,手机端响应式网站设计</p>
        </div>
      </li>
      <li class="service_item">
        <img src="../../assets/images/new_index_service_icon3.png" alt=""/>
        <div>
        <h4>微信小程序定制开发</h4>
        <p>小程序定制开发</p>
        <p>微信公众号定制开发</p>
        <p>基于微信的H5应用定制开发</p>
        </div>
      </li>
    </ul>
    <!--    pc版-->
    <ul class="new_service_list">
      <li class="service_item">
        <img src="../../assets/images/new_index_service_icon1.png" alt=""/>
        <h4>APP定制开发</h4>
        <p>ReactNative App开发</p>
        <p>原生iOS,安卓APP开发</p>
        <p>涵盖数十个行业数百个成功案例</p>
      </li>
      <li class="service_item">
        <img src="../../assets/images/new_index_service_icon2.png" alt=""/>
        <h4>高端网站制作</h4>
        <p>电商类网站开发</p>
        <p>高端企业官网设计与制作</p>
        <p>PC端,手机端响应式网站设计</p>
      </li>
      <li class="service_item">
        <img src="../../assets/images/new_index_service_icon3.png" alt=""/>
        <h4>微信小程序定制开发</h4>
        <p>小程序定制开发</p>
        <p>微信公众号定制开发</p>
        <p>基于微信的H5应用定制开发</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Service"
}
</script>

<style lang="less">
.service-container {
  display: flex;
  flex-direction: column;
  padding: 30px 0;

  .new_module_top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 35px;
    padding: 0 20px;

    h2 {
      color: #333;
      font-size: 18px;
      font-weight: 400;
    }

    h4 {
      color: #0a71ff;
      font-size: 14px;
      font-weight: 400;
      margin: 10px 0 20px;
    }

    p {
      color: #666;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }

  }

  .new_service_list {
    display: none;
  }

  .mobile-new_service_list {
    margin: 0 20px;

    li {
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgb(53 67 78 / 15%);
      padding: 20px;
      display: flex;

      img {
        height: 45px;
        width: 45px;
      }
      div{
        h4 {
          color: #333;
          font-size: 16px;
          font-weight: 400;
          margin: 0 0 15px 20px;
        }

        p {
          color: #666;
          font-size: 12px;
          line-height: 20px;
          margin-bottom: 0px;
          margin-left: 20px;
        }
      } 
    }

    li:nth-child(1) {
      background: url('../../assets/images/new_index_service_bg1.png') 0 0 repeat;
      background-size: cover !important;
    }

    li:nth-child(2) {
      background: url('../../assets/images/new_index_service_bg2.png') 0 0 repeat;
      background-size: cover !important;
      margin: 20px auto;
    }

    li:nth-child(3) {
      background: url('../../assets/images/new_index_service_bg3.png') 0 0 repeat;
      background-size: cover !important;
    }
  }
}

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .service-container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 90px 0;

      .new_module_top {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 35px;
        padding: 0 20px;

        h2 {
          color: #333;
          font-size: 40px;
          font-weight: 400;
        }

        h4 {
          color: #0a71ff;
          font-size: 20px;
          font-weight: 400;
          margin: 10px 0 20px;
        }

        p {
          color: #666;
          font-size: 14px;
          font-weight: 400;
          line-height: 28px;
        }

      }

      .new_service_list {
        display: flex;
        flex-direction: row;

        li {
          width: 33.3%;
          background: #fff;
          border-radius: 10px;
          box-shadow: 0 0 10px 0 rgb(53 67 78 / 15%);
          padding: 45px;
          img {
            height: 70px;
            width: 70px;
          }

          h4 {
            color: #333;
            font-size: 24px;
            font-weight: 400;
            margin: 40px 0 15px;
          }

          p {
            color: #666;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 0px;
          }
        }

        li:nth-child(1) {
          background: url('../../assets/images/new_index_service_bg1.png') 0 0 repeat;
          background-size: cover !important;
        }

        li:nth-child(2) {
          background: url('../../assets/images/new_index_service_bg2.png') 0 0 repeat;
          background-size: cover !important;
          margin: 0 20px ;
        }

        li:nth-child(3) {
          background: url('../../assets/images/new_index_service_bg3.png') 0 0 repeat;
          background-size: cover !important;
        }
      }
    }

    .mobile-new_service_list {
      display: none;
    }
  }
}

</style>

<template>
  <div class="new_index_links">
    <div class="new_container">
      <div class="service-container">
        <div class="new_module_top">
          <h2>友情链接</h2>
          <h4>— LINKS —</h4>
        </div>
        <div class="new_links_list">
          <ul class="clearfix">
            <li v-for="(item,index) in LinkData" :key="index" class="fl">
              <a rel="noreferrer" :href="item.url" target='_blank'>{{ item.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LinkData from '../../data/linkdata.json'

export default {
  name: "FriendLink",
  data() {
    return {
      LinkData
    }
  }
}
</script>

<style scoped lang="less">
.new_index_links {
  display: none;
}

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {

    .new_index_links {
      display: block;
    }

    .new_links_list {
      padding-top: 30px;

      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          margin-right: 15px;

          a {
            font-size: 14px;
            font-weight: 400;
            color: #333;
            line-height: 28px;
          }

          a:hover {
            color: #0A71FF;
            text-decoration: underline !important;
          }
        }
      }
    }

  }
}

</style>

export default {
  login: `/api/user/login`, //密码登陆
  userInfo: `/api/user/userInfo`, //用户信息
  userList: `/api/user/userList`, //用户列表
  createAccount: `/api/user/createAccount`, //创建账号
  updateAccount: `/api/user/updateAccount`, //更新账号
  deleteAccount: `/api/user/deleteAccount`, //删除账号
  systemInfo: `/api/system/info`, //系统信息
  updateSysInfo: `/api/system/updateInfo`, //更新系统信息
  uploadFile: `/api/utils/upload`, //上传文件
  
  createCaseType: `/api/case/type/create`, //创建案例分类
  updateCaseType: `/api/case/type/update`, //更新案例分类
  deleteCaseType: `/api/case/type/delete`, //删除案例分类
  caseTypeList: `/api/case/type/list`, //案例分类列表 
  getEnableList: `/api/case/type/enableList`, //案例可用分类列表 
  createCase: `/api/case/create`, //创建案例
  updateCase: `/api/case/update`, //更新案例
  deleteCase: `/api/case/delete`, //删除案例
  caseList: `/api/case/list`, //案例列表

  createNewsType: `/api/news/type/create`, //创建咨询分类
  updateNewsType: `/api/news/type/update`, //更新咨询分类
  deleteNewsType: `/api/news/type/delete`, //删除咨询分类
  newsTypeList: `/api/news/type/list`, //咨询分类列表
  newsEnableTypeList: `/api/case/type/enableList`, //咨询可用分类列表 
  createNews: `/api/news/create`, //创建咨询
  updateNews: `/api/news/update`, //更新咨询
  deleteNews: `/api/news/delete`, //删除咨询
  newsList: `/api/news/list`, //咨询列表

  createAdvertiseType: `/api/adMgr/type/create`, //创建广告分类
  updateAdvertiseType: `/api/adMgr/type/update`, //更新广告分类
  deleteAdvertiseType: `/api/adMgr/type/delete`, //删除广告分类
  advertiseTypeList: `/api/adMgr/type/list`, //广告分类列表
  advertiseEnableTypeList: `/api/adMgr/type/enableList`, //广告可用分类列表 
  createAdvertise: `/api/adMgr/create`, //创建广告
  updateAdvertise: `/api/adMgr/update`, //更新广告
  deleteAdvertise: `/api/adMgr/delete`, //删除广告
  advertiseList: `/api/adMgr/bannerList`, //广告列表
   
}

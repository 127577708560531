<template>
  <div>
    <!--手机版-->
    <div class="mobile-case-sort">
      <ul class="mobile-case-type">
        <li v-for="data in classifications" :key="data.id" class="cat-item">
          <router-link active-class="case-active" :to="`/case/list/${data.id}`">{{ data.name }}</router-link>
        </li>
      </ul>
    </div>
    <!--PC版-->
    <div class="case-warp csort">
      <div class="case-sort">
        <ul class="case-type">
          <li v-for="data in classifications" :key="data.id" class="cat-item">
            <router-link active-class="case-active" :to="`/case/list/${data.id}`">{{ data.name }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../net/axios";
import api from "../../net/api";

export default {
  name: "CaseTypes",
  mounted() {
    this.getData();
  },
  data() {
    return {
      classifications:[]
    }
  },
  methods:{
    getData() {
      axios.get(api.caseTypeList)
          .then(res => {
            this.classifications = res.data;
          })
          .catch(e => {
            console.log(e)
          })
    },
  }
}
</script>

<style scoped lang="less">
ol, ul, dl, p {
  margin-bottom: 0 !important;
  padding:0 !important;
}
.case-sort {
  display: none;
}

.case-active {
  color: #0A71FF !important;
  border-bottom: 1px solid #0A71FF !important;
  font-weight: bold;
}

.mobile-case-sort {
  display: flex;
  width: 100%;

  .mobile-case-type {
    display: flex;
    overflow-y: auto;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    background: #f8f8f8;

    &::-webkit-scrollbar {
      width: 0 !important
    }

    li {
      padding: 0px 15px;
      background: white;
      a {
        white-space: nowrap;
        display: block;
        padding: 10px 0px;
        border-bottom: 1px solid transparent;
        color: #333;
        font-size: 14px;

        &:active:extend(.active) {

        }
      }
    }
  }
}

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .mobile-case-sort {
      display: none;
    }
    .case-active { 
      color: #fff !important; 
      background: #0A71FF !important;
      border: 1px solid #0A71FF !important;
    }
    
    .case-sort {
      z-index: 999;
      overflow: hidden;
      padding: 30px 0;
      text-align: center;
      box-shadow: 0 0 3px #ddd;
      display: block;
    }

    .case-sort ul {
      height: 38px;
      text-align: center;
      border-radius: 6px;
    }

    .case-sort ul li {
      display: inline-block;
    }

    .case-sort ul li a {
      display: block;
      color: #888888;
      border: 1px solid #ddd;
      background: #fff;
      padding: 0 25px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      font-size: 16px;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      text-decoration: none;
      -webkit-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      -moz-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    }

    .case-sort ul li a {
      border-right: 0px;
    }

    .case-sort ul li:last-child a {
      border-right: 1px solid #ddd;
    }

    .case-sort ul li a:hover {
      background: rgba(50, 50, 50, 0.08);
      color: #333;
    } 
  }
}
</style>

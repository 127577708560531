<template>
  <div class="page-container">
    <AboutContent/>
    <Footer/>
  </div>
</template>

<script>
import Footer from "../../components/Footer";
import AboutContent from "../../components/About/AboutContent";

export default {
  name: "About",
  components: {Footer, AboutContent}
}
</script>

<style scoped>

</style>

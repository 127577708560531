<template>
  <div v-if="pageTotal>1" class="pages">
    <ul>
      <li v-if="pageNo == 1">首页</li>
      <li v-else><a href="#" @click="pageNo=1">首页</a></li>
      <li v-if="pageNo > 1"><a href="#" @click="pageNo=pageNo-1">上一页</a></li>
      <li v-for="page in Array.from({length: pageTotal},(a,b)=>b)" :key="page"
          :class="page + 1 == pageNo?'page-active':''">
        <a v-if="page + 1 != pageNo" href="#" @click="pageNo=page+1">{{ page + 1 }}</a>
        <a v-else="page + 1 != pageNo" href="#" @click="pageNo=page+1">{{ page + 1 }}</a>
      </li>
      <li v-if="pageNo != pageTotal"><a href="#" @click="pageNo=pageNo+1">下一页</a></li>
      <li v-if="pageNo == pageTotal">末页</li>
      <li v-else><a href="#" @click="pageNo=pageTotal">末页</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: ['totalPage','changePageNumber'],
  data() {
    return {
      pageTotal: this.totalPage,
      pageNo: 1,
    }
  }, 
  watch:{
    pageNo(value){
      this.changePageNumber(value)
    },
    totalPage(value){
      this.pageTotal=value  
      this.pageNo=1
    }
  }
}
</script>

<style scoped lang="less">

.pages {
  margin-top: 20px;
  margin-bottom: 5px;
  height: 40px;
  padding-bottom: 10px;
  text-align: center;
}

.pages ul {
  margin: 0 auto;
}

.pages .firstPage {
  width: 30px;
}

.pages ul li {
  line-height: 25px;
  height: 25px;
  margin-left: 5px;
  font-size: 12px;
  border: 1px solid #E9E9E9;
  padding: 0px 10px;
  width: auto;
  display: inline-block;
  a{
    color: #333;
    text-decoration: none;
  }
}

.pages ul li a:hover {
  color: #0A71FF;
  text-decoration: none;
}

.pages ul li.page-active, .pages ul li.page-active a, .pages ul li.page-active a:hover {
  background-color: #0A71FF;
  color: #FFF
}

.pages .pageinfo {
  line-height: 25px;
  padding: 12px 0px 10px 5px;
  color: #999;
}

.pages select {
  line-height: 25px;
  margin-top: 4px
}

.pages .pageinfo strong {
  color: #555;
  font-weight: normal;
  margin: 0px 2px;
}
</style>

<template>
  <div>
    <b-carousel
        ref="myCarousel"
        v-model="slide"
        :interval="4000"
        indicators
        background="#ababab"
        img-width="1820"
        img-height="600"
        style="text-shadow: 1px 1px 2px #333;"
    >
      <b-carousel-slide v-slot:img v-for="(p,index) of bannerData" :key="index">
        <template>
          <img
              @click="openChat()"
              class="d-block img-fluid w-100"
              :src="p.picUrl"
              alt="image slot"
          >
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>


<script>
import api from "../../net/api";
import axios from "../../net/axios";

export default {
  name: "Banner",
  data() {
    return {
      slide: 0,
      sliding: null,
      bannerData: [
        {
          "id": 0,
          "imgPath": require("../../assets/images/new_index_banner1.jpg")
        },
        {
          "id": 1,
          "imgPath": require("../../assets/images/new_index_banner3.jpg")
        },
        {
          "id": 2,
          "imgPath": require("../../assets/images/new_index_banner2.jpg")
        }
      ],
    }
  },
  methods: {

    openChat() {
      window.openChat()
    },
    getData() {
      axios.post(api.advertiseList)
          .then(res => {
            this.bannerData = res.data;
          })
          .catch(e => {
            console.log(e)
          })
    },
  },
  mounted() {
    this.getData();
    this.$refs.myCarousel.start()
  }
}
</script>

<style scoped lang="less">


</style>
